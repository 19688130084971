<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      level_one_chart_of_accounts: [],
      main_chart: [],
      LevelOneChartOfAccounts: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchLevelOneChartOfAccounts: null,
      LevelOneChartOfAccountsSearchMode: false,
      // appprices:[]
    };
  },
  methods: {
    addLevelOneChartOfAccounts() {
      var data = {
        title: "popups.addlevel",
        inputs: [
          {
            model: "main_chart_of_accounts_type_id",
            type: "select",
            options: (() => {
              return this.main_chart.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.main-chart-of-accounts",
          },
          { model: "name", type: "text", label: "popups.name" },
          { model: "code", type: "text", label: "popups.code" },

          {
            model: "is_editable",
            type: "select",
            options: [
              { label: "Editable", value: 1 },
              { label: "Uneditable", value: 0 },
            ],
            label: "popups.edit",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("level-one-chart-of-accounts", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editLevelOneChartOfAccounts(app) {
      var data = {
        title: "popups.editlevel",
        inputs: [
          {
            model: "main_chart_of_accounts_type_id",
            type: "select",
            options: (() => {
              return this.main_chart.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.main-chart-of-accounts",
            value: app.main_chart_of_accounts_type,
          },

          {
            model: "name",
            type: "text",
            label: "popups.name",
            value: app.name,
          },
          {
            model: "code",
            type: "text",
            label: "popups.code",
            value: app.code,
          },

          {
            model: "is_editable",
            type: "select",
            options: [
              { label: "Editable", value: 1 },
              { label: "Uneditable", value: 0 },
            ],
            label: "popups.edit",
            value: app.is_editable,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http
                .put("level-one-chart-of-accounts", app.id, obj)
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.LevelOneChartOfAccountsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("level-one-chart-of-accounts/search", {
          search: this.searchModel,
          col: "name",
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          this.level_one_chart_of_accounts = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.LevelOneChartOfAccountsSearchMode = false;
      this.get(this.page);
    },
    // getLevelOneChartOfAccounts(){
    // this.http.get("level-one-chart-of-accounts").then((res)=>{
    // this.level_one_chart_of_accounts = res.data
    // })
    // },
    deleteLevelOneChartOfAccounts(app) {
      var data = {
        title: "popups. deletesms",
        template: `
          <h5>Are you sure you want to delete ?</h5>

        `,
        buttons: [
          {
            text: "Delete",
            closer: true,
            color: "danger",
            handler: () => {
              console.log(app);
              this.http
                .delete("level-one-chart-of-accounts", app.id)
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    getMainChart() {
      this.http.get("main-chart-of-accounts-types").then((res) => {
        this.main_chart = res.data;
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("level-one-chart-of-accounts/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.level_one_chart_of_accounts = res.data;
        });
    },
  },
  created() {
    this.get(1);
    // this.getLevelOneChartOfAccounts()
    this.getMainChart();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('popups.levelone')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close close text-light"
          v-if="LevelOneChartOfAccountsSearchMode"
          @click="cancelappsearchMode()"
        ></button>

        <button
          type="button"
          @click="addLevelOneChartOfAccounts()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("level_one.id") }}</th>
          <th scope="col">{{ $t("level_one.main_chart_of_accounts") }}</th>
          <th scope="col">{{ $t("level_one.name") }}</th>
          <th scope="col">{{ $t("level_one.code") }}</th>
          <th scope="col">{{ $t("level_one.created") }}</th>
          <th scope="col">{{ $t("level_one.updated") }}</th>
          <th scope="col">{{ $t("level_one.operations") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(app, index) in level_one_chart_of_accounts"
          :key="app"
          class="text-center"
        >
          <td>{{ index + 1 }}</td>
          <td>{{ app?.main_chart_of_accounts_type?.name }}</td>
          <td>{{ app.name }}</td>
          <td>{{ app.code }}</td>
          <td>{{ app?.created.split("T")[0] }}</td>
          <td>{{ app?.updated.split("T")[0] }}</td>
          <td>
            <a
              class="btn btn-primary me-2"
              v-if="app.is_editable == 1"
              @click="editLevelOneChartOfAccounts(app)"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.edit") }}</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="video"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal video</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <video style="width: 470px; height: 360px" controls>
              <source src="/Rec 0008.mp4" type="video/mp4" />
              <source src="/Rec 0008.mp4" type="video/ogg" />
              <source src="/Rec 0008.mp4" type="video/webm" />
              <source src="/Rec 0008.mp4" type="video/x-flv" />
            </video>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>

      <!--end-->
      <!-- Modal -->
      <div
        class="modal fade w-100"
        id="Add"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="section">
                <h1>Basic Information</h1>

                <div class="row"></div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <label class="form-label mt-2">
                      Programming Language
                    </label>
                    <select class="form-select" v-model="c">
                      <option>Python</option>
                      <option>PHP</option>
                      <option>NodeJS</option>
                      <option>Java</option>
                    </select>
                  </div>
                  <div class="col">
                    <label class="form-label mt-2">Icon </label>
                    <input type="file" class="form-control" />
                  </div>
                </div>
              </div>
            </div>

            <br /><!--end--><br />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Add
            </button>
            <button type="button" class="btn btn-primary">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!--end-->
    <!--   Apps  Pagination     -->
    <div>
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
